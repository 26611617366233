import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import '../../css/CreateAccount.css'
import { apiRequest } from '../../Data/Api/ApiClass'

function CreateAccount() {

    const crypto = require('crypto-js')

    const nav = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [message, setMessage] = useState("")

    const email = useRef('');
    const password = useRef('');
    const password1 = useRef('');
    const address = useRef('');
    const firstName = useRef('');
    const lastName = useRef('');

    async function createAccount(){

        if(password.current !== password1.current){
            setMessage('Passwords dont match')
            return false;
        }

        if(email.current == "" || password.current == "" || password1.current == "" || address.current == "" || firstName.current == "" || lastName.current == ""){
            setMessage('Fields cannot be empty')
            return false;
        }

        const body = {
            email: `${email.current}`,
            password: `${crypto.SHA256(password.current)}`,
            address: `${address.current}`,
            name: `${firstName.current} ${lastName.current}`
        }
        const response = await apiRequest('users/createaccount', body)
        setMessage(response.data.message)
      }

  return (
    <>
        <div className='createAccountContainer'>
            <h1 className='createHeader'>Create Account</h1>
            <div style={{width: '82%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <input placeholder='First Name' style={{width: '48%', height: 25, marginRight: '4%'}} onChange={(t) => {firstName.current = t.target.value}}/>
                <input placeholder='Last Name' style={{width: '48%', height: 25}} onChange={(t) => {lastName.current = t.target.value}}/>
            </div>
            <input className='createInputField' placeholder='Email Address' onChange={(t) => {email.current = t.target.value}}/>
            <input className='createInputField' placeholder='Mountain Lakes Address' onChange={(t) => {address.current = t.target.value}}/>
            <span style={{width: '100%', textAlign: 'center'}}>
                <input className='createInputField' type={showPassword ? 'text' : 'password'} placeholder='Password' style={{marginBottom: 10}} onChange={(t) => {password.current = t.target.value}}/>
                <input className='createInputField' type={showPassword ? 'text' : 'password'} placeholder='Re-enter password' style={{marginBottom: 10}} onChange={(t) => {password1.current = t.target.value}}/>
                <div className='showPasswordButton'>
                    <label style={{userSelect: 'none'}}>
                        <input type='checkbox' checked={showPassword} onChange={() => {setShowPassword(!showPassword)}}/>
                        Show Password
                    </label>
                </div>
            </span>
            <button onClick={createAccount}>Create Account</button>
        </div>
        <p style={{width: '100%', textAlign: 'center'}}>{message}</p>
    </>
  )
}

export default CreateAccount