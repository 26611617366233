import React, { useEffect, useRef, useState } from 'react'
import '../../css/Documents.css'
import { apiRequest } from '../../Data/Api/ApiClass'
// import { read } from 'fs'

function DocumentUploadForm({currentPath, setShowUploadForm, showUploadForm, getFileTree}) {

    const [type, setType] = useState('Folder')
    const [name, setName] = useState('')

    const [fileName, setFileName] = useState(null)
    const [fileBinary, setFileBinary] = useState(null)

    // useEffect(() => {
    //     console.log(fileBinary)
    // }, [fileBinary])

    function getFileUploadedFileData(event) {
        const file = event.target.files[0]
        const reader = new FileReader()
        
        reader.onload = (e) => {
            console.dir(e)
            const binaryData = e.target.result;
            setFileName(file.name)

            const uint8Array = new Uint8Array(binaryData);

            const binaryString = uint8Array.reduce((accumulator, value) => {
                return accumulator + String.fromCharCode(value);
              }, '');
            setFileBinary(binaryString)
        }

        if(file != null)
            reader.readAsArrayBuffer(file)
    }

    async function createNewFolder(){

      const response = await apiRequest('documents/createFolder', {path: `/${currentPath}/${name.trim()}`, name: `${name.trim()}`})
      if(response.status == 200){
        getFileTree()
        setShowUploadForm(null)
      }
    }
    async function uploadPDF(){

      const response = await apiRequest('documents/uploadPDF', {path: `/${currentPath}/${fileName}`, binary: `${fileBinary}`})
      if(response.status == 200){
        getFileTree()
        setShowUploadForm(null)
      }
    }

  return (
    <div className='documentBackgroundContainer'>
        <div className='documentPopupContainer'>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginLeft: 20, marginRight: 20}}>
                <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row', alignItems: 'center'}}>
                    {/* <h2 style={{margin: 0, fontSize: 16}}>Upload Type: {showUploadForm}</h2> */}
                    <img src={require('../../images/documents/upload_icon.png')} style={{aspectRatio: 1, width: 50}}/>
                    <h2>{showUploadForm} Upload</h2>
                </div>
                <div style={{marginTop: 20, marginBottom: 25}}>
                    <p style={{margin: 0}}>Target Path:</p>
                    <h3 style={{margin: 0}}>Documents/{currentPath}/{name}</h3>
                </div>
                
                {
                    showUploadForm == 'Folder' ? 
                    <input placeholder={`${type} name`} type='text' onInput={(e) => {setName(e.target.value)}} style={{width: 250}}/> :

                    <input type='file' accept='.pdf' onChange={(e) => {getFileUploadedFileData(e)}}/>
                }

            </div>
            
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                <p className='documentPopupButtons' onClick={() => {
                    setShowUploadForm(null)
                    setFileBinary(null)
                }}>Cancel</p>
                <p className='documentPopupButtons'
                    onClick={() => {
                        showUploadForm == 'Folder' ? createNewFolder() : uploadPDF()
                    }}
                >Upload</p>
            </div>
        </div>
    </div>
  )
}

export default DocumentUploadForm