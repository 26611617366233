import React, { useRef } from 'react'
import '../../css/Signin.css'
import { useState } from 'react';
import { useNavigate } from 'react-router';
import useKeypress from 'react-use-keypress';
import { apiLogin, apiRequest } from '../../Data/Api/ApiClass';

function SigninPage({setSessionToken}) {

    const crypto = require('crypto-js')

    const nav = useNavigate()

    

    const [resetPW, setResetPW] = useState(false)

    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [pass1, setPass1] = useState("");
    const [userID, setUserID] = useState("");
    const [message, setMessage] = useState("");

    const pwRef = useRef();
    const pw1Ref = useRef();

    useKeypress('Enter', () => {
        console.log('mongo bongo')
        if(resetPW == true){
            resetPassword()
        }
        else{
            signIn()
        }
    })

    async function resetPassword(){
        if(pass != pass1){
            setMessage("Passwords don't match")
            return;
        }

        if(pass == "" || pass1 == ""){
            setMessage("Passwords cant be blank")
            return;
        }
        const hashPW = crypto?.SHA256(pass)?.toString()
        try{
            const data = await apiRequest('users/resetpassword', {password: hashPW, id: userID, email: email})
            sessionStorage.setItem('token', data.data.token)
            setSessionToken(data.data.token)
            nav('/home')
        } catch(e){
            console.log(e)
        }
    }

    async function signIn(){
        const hashPW = crypto.SHA256(pass)

        if(email == ""){
            setMessage('Email cannot be blank');
            return;
        }
        
        const data = await apiLogin({email: `${email}`, password: `${hashPW}`})

        console.log(data)

        if(data.status == 200){
            console.log(data.data.token)
            sessionStorage.setItem('token', data.data.token)
            setSessionToken(sessionStorage.getItem('token'))
            nav('/announcements')
        } else if(data.status == 201) {
            setUserID(data.data.id)
            setResetPW(true)
            setPass("")
        } else if(data.status == 401) {
            setMessage(data.data.message)
        }
    }

    function generateForm(){
        switch(resetPW){
            case false: 
                return (< >
                    <h1>Login</h1>

                        <div className='inputRow'>
                            <input type="text" placeholder='Email' onChange={(e) => {setEmail(e.target.value)}}/>
                            <input type="password" placeholder='Password' onChange={(e) => {setPass(e.target.value)}}/>
                        </div>


                        <p className='feedbackText'> </p>


                        <div className='signinButtonRow' onKeyDown={(e) => {console.log('boingo')}} >
                            <div className='signinButton' onClick={signIn} >
                                <p>Sign in</p>
                            </div>
                        </div>
                        <p style={{width: '100%', textAlign: 'center'}}>{message}</p>
                        
                </>)
            case true:
                
                return (<>
                    <h1> Your account has been marked for a password reset</h1>
                    <div className='inputRow'>
                        <input ref={pwRef} type="password" value={pass} placeholder='Password' onChange={(e) => {setPass(e.target.value)}}/>
                        <input ref={pw1Ref} type="password" value={pass1} placeholder='Re-type Password' onChange={(e) => {setPass1(e.target.value)}}/>
                    </div>
                    <div className='signinButtonRow' onKeyDown={() => {console.log('boingo')}}>
                        <div className='signinButton' onClick={resetPassword} >
                            <p>Reset and Sign in</p>
                        </div>
                    </div>
                    <p style={{width: '100%', textAlign: 'center'}}>{message}</p>
                </>)
        }
    }

  return (
    <div className="signinContainer blackBorder">
        {generateForm()}
    </div>
  )
}

export default SigninPage