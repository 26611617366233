import React, { useEffect, useState } from 'react'
import '../../css/UserTable.css'
import { apiRequest } from '../../Data/Api/ApiClass'


function UserEntry({userData, setTrigger, url}) {
    const token = sessionStorage.getItem('token')

    const [showAction, setShowAction] = useState(false)

    async function markUser(state){ 
        try {
          const response = await apiRequest('users/markUserState', {id: userData.id, state: state})
          if(response.status == 200){
            setShowAction(false);
            setTrigger(prev => {return prev+1;})
          }
        } catch (e){
          alert(e)
        }
    }
    async function deleteUser(){
      // const settings = {
      //   headers: new Headers({
      //     "content-type": "application/json"
      //   }),
      //   body: JSON.stringify({
      //     'token': `${token}`,
      //     'id': `${userData.id}`,
      //   }),
      //   method: 'POST'
      // }
      // fetch(`${url}/`, settings).then(response => response.text()).then(data => {
      //   console.log('data: ' + data)
      //   setShowAction(false);
      //   setTrigger(prev => {
      //       return prev+1;
      //    })
      // })
      const response = await apiRequest('users/deleteUser', {id: userData.id})
      console.log(response)
      // try {
        
        
      //   if(response.status == 200){
      //     setShowAction(false);
      //     setTrigger(prev => {return prev+1;})
      //   }
      // } catch(e){
      //   console.log(userData.id)
      //   alert(e)
      // }

    }
    function markPasswordReset(){
      // const settings = {
      //   headers: new Headers({
      //     "content-type": "application/json"
      //   }),
      //   body: JSON.stringify({
      //     'token': `${token}`,
      //     'id': `${userData.id}`,
      //   }),
      //   method: 'POST'
      // }
      // fetch(`${url}/users/markpasswordreset`, settings).then(response => response.json()).then(data => {
      //   setShowAction(false);
      //   console.log(data.message)
      //   setTrigger(prev => {
      //       return prev+1;
      //     })
      //   })
      apiRequest('users/markpasswordreset', {id: userData.id}).then(() => setTrigger(prev => prev + 1))
    }

    function actionButtons(){
      // var status = Number(userData.activated)

      

      switch(Number(userData.approved)){
        case 0:
          return (
            <td className='pendingEntryText'>
                <span style={{marginRight: 10}} className='userButton approveButton' onClick={() => markUser(1)}>Approve</span>
                <span className='userButton denyButton' onClick={() => markUser(-1)}>Deny</span>
            </td>
          )
        case 1:
          return (
            <td className='pendingEntryText'>
                <span style={{marginRight: 10}} className='userButton approveButton' onClick={() => markUser(1)}>Verify</span>
                <span className='userButton denyButton' onClick={() => markUser(-1)}>Deny</span>
            </td>
          )
        case -1:
          return (
            <td className='pendingEntryText'>
                <span style={{marginRight: 10}} className='userButton approveButton' onClick={() => markUser(1)}>Approve</span>
                <span className='userButton denyButton' onClick={deleteUser}>Delete</span>
            </td>
          )
      }

    }

  return (
    <>
      <tr className='userRow' style={{backgroundColor: showAction ? 'transparent' : 'transparent'}} onClick={() => {setShowAction(!showAction)}}>
          <td className='pendingEntryText'>{userData.id}</td>
          <td className='pendingEntryText'>{userData.name}</td>
          <td className='pendingEntryText'>{userData.email}</td>
          {/* <td className='pendingEntryText'>{userData.activated == '1' ? 'Verified' : 'Not Verified'}</td> */}
          
      </tr>
      {showAction && 
      <tr style={{backgroundColor: showAction ? 'transparent' : 'transparent'}}>
        <td colSpan={6}>
          <div style={{width: '100%', height: 30, marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            <h4 style={{margin: 0}}>Address: {userData.address}</h4>
            <h4 style={{margin: 0}}>{userData.resetPassword == '1' ? 'Marked for reset' : ''}</h4>
            <h4 style={{margin: 0}}>Last logon: {userData.lastLogonDate == null ? 'Never' : userData.lastLogonDate}</h4>
          </div>
          <div style={{width: '100%', height: 20, marginTop: 0, marginBottom: 40, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            <p className='userButton' style={{margin: 0}} onClick={() => markUser(1)}>Approve User</p>
            <p className='userButton' style={{margin: 0}} onClick={() => markUser(-1)}>Deny User</p>
            {Number(userData.approved) == -1 && <p className='userButton' style={{margin: 0}} onClick={deleteUser}>Delete User</p>}
            <p className='userButton' style={{margin: 0}} onClick={markPasswordReset}>Mark Password Reset</p>
          </div>
        </td>
      </tr>}
      {/* {showAction && <div className='userDetailBackground'>
          <div className='userDetailContainer'>
            <h1>{userData.name}</h1>
          </div>
      </div>} */}
    </>
  )
}

export default UserEntry