import React, { useEffect, useState } from 'react'
import '../../css/UserEdit.css'
import jwtDecode from 'jwt-decode';
import { apiRequest, decodedToken } from '../../Data/Api/ApiClass';

function UserEdit() {
    const userID = decodedToken().id

    const [oldEmail, setOldEmail] = useState(null)
    const [oldAddress, setOldAddress] = useState(null)
    const [oldPhone, setOldPhone] = useState(null)
    const [directoryStatus, setDirectoryStatus] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        getUserInfo()
    }, [])

    async function getUserInfo(){
        try{
            const response = await apiRequest('users/getUserInfo', {id: userID})
            if(response.status == 200){
                const data = response.data;

                data.email == 'null' ? setOldEmail('') : setOldEmail(data.email)
                data.address == 'null' ? setOldAddress('') : setOldAddress(data.address)
                data.phoneNumber == 'null' ? setOldPhone('') : setOldPhone(data.phoneNumber) 
                data.visibility == 'null' ? setDirectoryStatus('private') : setDirectoryStatus(data.visibility)

            } else {
                alert('Invalid Token, please log in again')
            }
        } catch (e){
            alert('Please Sign in')
        }
    }

    async function changeVisibility(vis){
        try {
            const response = await apiRequest('users/changeVisibility', {visibility: vis, id: userID})
            if(response.status == 200){
                setDirectoryStatus(response.data.visibility)
            }
        } catch(e){
            console.log(e)
            alert('Please Sign In')
        }
    }

    async function updateUserInfo(){
        try {
            const body= {
                            email: `${oldEmail}`,
                            address: `${oldAddress}`,
                            phone: `${oldPhone}`
                        }
            const response = await apiRequest('users/updateUserInfo', body)
            if(response.status == 200){
                setError('Saved!')
                setTimeout(() => {
                    setError('')
                }, 1500)
                getUserInfo()
            }
        } catch (e){
            console.log(e)
            alert('Please sign in')
        }
    }

    

    function renderDirectoryButtons(){
        if(directoryStatus == 'public'){
            return (
                <div className='directorySelect'>
                    <p className='optInButton' onClick={() => {changeVisibility('private')}}>Opt out of the community directory</p>
                    <p className='statusText'>You are currently a member of the directory</p>
                </div>
            )
        }
        else {
            return (
                <div className='directorySelect'>
                    <p className='optInButton' onClick={() => {changeVisibility('public')}}>Opt into the community directory</p>
                    <p className='statusText'>You are currently NOT a member of the directory</p>
                </div>
            )
        }
    }

  return (
    <>
        <div className='userEditContainer'>
            <h1>Edit Account Details</h1>
            <div className='accountEditInputRow'>
                <h2>Email</h2>
                <input type='text' style={{height: 20}} value={oldEmail} onChange={(e) => {setOldEmail(e.target.value)}}/>
            </div>

            <div className='accountEditInputRow'>
                <h2>Phone Number</h2>
                <input type='text' style={{height: 20}} value={oldPhone} onChange={(e) => {setOldPhone(e.target.value)}}/>
            </div>

            <div className='accountEditInputRow'>
                <h2>Mountain Lakes Address</h2>
                <input type='text' style={{height: 20}} value={oldAddress} onChange={(e) => {setOldAddress(e.target.value)}}/>
            </div>

            {renderDirectoryButtons()}
            
            <button onClick={() => {updateUserInfo()}}>Save Information</button>
            <p style={{position: 'absolute', bottom: 0, width: '100%', left: 0}}>{error}</p>
        </div>
        
    </>
  )
}

export default UserEdit