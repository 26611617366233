import React from 'react'
import '../../css/PopupDirectory.css'
import { apiRequest } from '../../Data/Api/ApiClass'


function DirectoryPopup({userID, setPopUp}) {
    // console.log(userID)

    async function changeVisibility(vis){
        const response = await apiRequest(`users/changeVisibility`, {id: `${userID}`, visibility: `${vis}`})
        if(response.status == 200)
            setPopUp(null)        
    }

  return (
    <div className='backgroundContainer'>
        <div className='popupDirectoryContainer'>
            <h1>New resident directory added!</h1>
            <h2>Do you want to be included?</h2>
            <div>
                <h4 className='selectionButtons' onClick={() => {changeVisibility('public')}}>Yes, I want to be included</h4>
                <h4 className='selectionButtons' onClick={() => {changeVisibility('private')}}>No, keep my information private</h4>
            </div>
            <p>*Will display your name and provided Mountain Lakes address. This can be changed in your account settings at a later date</p>
        </div>
        
    </div>
    
  )
}

export default DirectoryPopup