import React from 'react'
import '../../css/Members.css'

function MemberEntry({role, name, email}) {
  return (
    <div className='memberContainer'>
        <h1 className='verticalMargin name'>{name}</h1>
        <p className='verticalMargin role'>{role}</p>
        <p className='verticalMargin'>{email}</p>
    </div>
  )
}

export default MemberEntry