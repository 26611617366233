import React, { useEffect } from 'react'
import '../../css/Announcements.css'
import { apiRequest, decodedToken, isAdmin } from '../../Data/Api/ApiClass'

function AnnouncementEntry({item, updateAnnouncements}) {

  async function deletePost(){
    const data = await apiRequest('announcements/deletePost', {id: item.id})
    updateAnnouncements()
  }

    return (
        <div className={'card mx-5 shadow'}>
            <div className={'card-body py-4'}>
                <p className={'h6 fw-normal'} style={{whiteSpace: 'pre-wrap'}}>{item.body}</p>
            </div>
            <div className={'card-footer d-flex flex-row justify-content-between'}>
                <p className={'fw-semibold h4'}>{item.author}</p>
                {
                    isAdmin() &&
                      <div className='deleteButton' onClick={() => {deletePost()}}>
                        Delete Post
                      </div>
                  }
            </div>
        </div>
    )

  // return (
  //   <div className='entryContainer'>
  //       <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
  //           <div className='bodyText'>
  //               <h1>{item.title}</h1>
  //               <p>{item.body}</p>
  //               <div className='informationContainer'>
  //                   <h1>Posted by {item.author}</h1>
  //                   <h2>{item.date}</h2>
  //               </div>
  //               {
  //                 isAdmin() &&
  //                 <div style={{justifyContent: 'flex-end', display: 'flex'}}>
  //                   <div className='deleteButton' onClick={() => {deletePost()}}>
  //                     Delete Post
  //                   </div>
  //                 </div>
  //               }
  //           </div>
  //       </div>
  //   </div>
  //
  // )
}

export default AnnouncementEntry