import React from 'react'
import '../../css/Directory.css'

function DirectoryEntry({user, id}) {
    console.log(user)
  return (
    <tr>
        <td>{user.name ? user.name : 'N/A'}</td>
        <td>{user.email ? user.email : 'N/A'}</td>
        <td>{user.phoneNumber ? user.phoneNumber : 'N/A'}</td>
        <td>{user.address ? user.address : 'N/A'}</td>
    </tr>
  )
}

export default DirectoryEntry