import React from 'react'
import Calendar from '../../Components/Calendar/Calendar'


function CalendarPage() {
  return (
    <div style={{flex: 1, display: 'flex', justifyContent: 'center', paddingBottom: '10%'}}>
        <Calendar />
    </div>
    
  )
}

export default CalendarPage