import React from 'react'
import '../../css/HomePage.css'

function HomePage() {
  return (
    <>

    <div className={'container-fluid p-0'} style={{display: 'flex', height: '100%'}}>


        <div className='backdropContainer position-absolute bottom-0'>
            <img src={require('../../images/wide-backdrop_cropped-removebg_1.png')} className={"w-100"}/>
        </div>
        <div className='imageRow'>
            <img src={require("../../images/mountainlakes.png")} style={{height: 250, marginLeft: 0}}/>
            <div className='textContainer'>
                <h1>Mountain Lakes Community Website</h1>
                <p>Please make an account and wait to be verified</p>
            </div>
        </div>
    </div>

    </>
  )
}

export default HomePage