import React, { useEffect, useRef, useState } from 'react'
import '../../css/PostCalendar.css'
import ReturnButton from '../../Components/Admin/ReturnButton';
import { apiRequest } from '../../Data/Api/ApiClass';

function AddCalendar() {

    const token = sessionStorage.getItem('token')

    const typeRef = useRef();
    const titleRef = useRef();
    const descRef = useRef();
    const dateRef = useRef();
    const timeRef = useRef();

    const [message, setMessage] = useState("");

    function printRefs(){

        const year = Number(String(dateRef.current.value).split('-')[0])
        const month = Number(String(dateRef.current.value).split('-')[1])
        const day = Number(String(dateRef.current.value).split('-')[2])

        const time = timeRef.current.value

        console.log(month + " | " + day + " | " + year + " at " + time)
    }

    async function addEvent(){

        const year = Number(String(dateRef.current.value).split('-')[0])
        const month = Number(String(dateRef.current.value).split('-')[1])
        const day = Number(String(dateRef.current.value).split('-')[2])

        const desc = descRef.current.value == "" ? "N/A" : descRef.current.value;
        // setMessage(desc);

        // clearInputs()

        const body = {
            token: `${token}`,
            title: `${titleRef.current.value}`,
            type: `${typeRef.current.value}`,
            desc: `${desc}`,
            month: `${month}`,
            year: `${year}`,
            day: `${day}`,
            time: `${timeRef.current.value}`
        }


        const response = await apiRequest('calendar/addEvent', body)
        setMessage(response.data.message)
        if(response.status == 200)
            clearInputs()
    }

    function clearInputs(){
        titleRef.current.value = '';
        descRef.current.value = '';
        dateRef.current.value = '';
        timeRef.current.value = '';
    }

  return (
    <>
        <ReturnButton />
        <div className='addCalendarContainer'>
            <h1>Add Calendar Event</h1>
            <span style={{marginBottom: 20}}>
                <h3 style={{margin: 0}}>Event Type</h3>
                <select ref={typeRef} onChange={(e) => {console.log(e.target.value)}}>
                    <option>MLR Quarterly Meeting</option>
                    <option>Residential Clubhouse Reservation</option>
                    <option>Neighborhood meeting</option>
                    <option>Community Event</option>
                    <option>Pool Reservation</option>
                    <option>Committee Meeting</option>
                </select>
            </span>
            <span style={{marginBottom: 20}}>
                <h3 style={{margin: 0}}>Event Date And Time</h3>
                <input type='date' placeholder='N/A' ref={dateRef} style={{marginRight: 10}}/>
                <input type='time' placeholder='N/A' ref={timeRef}/>
            </span>
            <span style={{marginBottom: 20}}>
                <h3 style={{margin: 0}}>Event Title</h3>
                <input type='text' ref={titleRef}/>
            </span>
            <span style={{marginBottom: 20}}>
                <h3 style={{margin: 0}}>Event Description</h3>
                <textarea type='text' placeholder='N/A' ref={descRef}/>
            </span>
            <span>
                <button onClick={addEvent}>Post Event</button>
            </span>
            <p>{message}</p>
            
        </div>
    </>
  )
}

export default AddCalendar