import React from 'react'
import { useNavigate } from 'react-router'
import '../../css/Admin.css'

function AdminButton({title, img, path}) {

    const nav = useNavigate()
    function adminClick(){
        nav(path)
    }

  return (
    <div className='adminButton shadow' onClick={adminClick}>
        <img src={require("../../images/admin/" + img)}/>
        <h4>{title}</h4>
    </div>
  )
}

export default AdminButton