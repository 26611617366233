import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import AdminButton from '../../Components/Admin/AdminButton'
import '../../css/Admin.css'
import { apiRequest } from '../../Data/Api/ApiClass'

function AdminPage() {

  const nav = useNavigate()
  useEffect(() => {
    async function load(){
      const data = await apiRequest('users/validateAdmin')
      if(data.status != 200)
        nav('/')
    } load()
  }, [])

  return (
    <>
    <h1 style={{textAlign: 'center'}}>Admin Tools</h1>
      <div className='adminButtonGrid'>
        <AdminButton title="Pending Users" img='pending_icon.png' path="/pendingUsers"/>
        <AdminButton title="Approved Users" img='add-friend.png' path="/approvedUsers"/>
        <AdminButton title="Denied Users" img='block-user.png' path="/deniedUsers"/>
        <AdminButton title="Post Announcement" img='megaphone.png' path="/postAnnouncement" />
        <AdminButton title="User Reports" img='checklist.png' path="/adminUserReports"/>
        <AdminButton title="Calendar Events" img='calendar.png' path="/addCalendar"/>
        
      </div>
      
      
    </>
  )
}

export default AdminPage