import React, { useEffect, useState } from 'react'
import DirectoryEntry from './DirectoryEntry';
import { apiRequest } from '../../Data/Api/ApiClass';

function Directory() {

    const [directory, setDirectory] = useState([])

    useEffect(() => {
        getDirectory()
    }, [])

    async function getDirectory(){
        const data = await apiRequest('users/getDirectory', {})
        setDirectory(data.data)
    }


    return (
            <div >
                <table className={'w-100 table table-bordered'}>
                    <thead>
                        <tr>
                            <td className={'flex-fill fw-bold'}>Name</td>
                            <td className={'flex-fill fw-bold'}>Email</td>
                            <td className={'flex-fill fw-bold'}>Phone</td>
                            <td className={'flex-fill fw-bold'}>Address</td>
                        </tr>
                    </thead>
                    <tbody>
                       {
                              directory.map((user, ind) => {

                                  return <DirectoryEntry user={user} key={ind} id={ind}/>
                              })
                       }
                    </tbody>
                </table>
            </div>

    )

  // return (
  //   <div>
  //       <h1 style={{textAlign: 'center'}}>Mountain Lakes Residential Directory</h1>
  //       <div className='headerRow'>
  //           <p>Name</p>
  //           <p>Email</p>
  //           <p>Phone</p>
  //           <p>Address</p>
  //       </div>
  //       {
  //           directory.map((user, ind) => {
  //               return <DirectoryEntry user={user} key={ind} id={ind}/>
  //           })
  //       }
  //   </div>
  // )
}

export default Directory