import React, { useEffect, useState } from 'react'
import AnnouncementEntry from '../../Components/Announcements/AnnouncementEntry'
import { useNavigate } from 'react-router'
import { apiRequest } from '../../Data/Api/ApiClass'

function AnnouncementsPage({url}) {

  const [announcements, setAnnouncements] = useState([])


  async function loadAnnouncements() {
    const data = await apiRequest('announcements/getAnnouncements', {test: 'hello world'})
    setAnnouncements(data.data)
  }

  useEffect(() => {
     loadAnnouncements()
  }, [])
  

  return (
    <>
      {
        announcements.length === 0 && <h2 style={{width: '100%', textAlign: 'center', marginTop: '10%'}}>No announcements posted</h2>
      }

      <div className={'gap-5 d-flex flex-column mt-3'}>
        {announcements.map( post => {
          return <AnnouncementEntry item={post} updateAnnouncements={loadAnnouncements}/>
        })}
      </div>

    </>

  )
}

export default AnnouncementsPage