import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import '../../css/UserTable.css'
import UserEntry from '../../Components/Admin/UserEntry'
import ReturnButton from '../../Components/Admin/ReturnButton'
import { apiRequest } from '../../Data/Api/ApiClass'



function UserList({state, url}) {

    const [users, setUsers] = useState([])
    const [trigger, setTrigger] = useState(0)

    const [searchTerm, setSearch] = useState('')

    useLayoutEffect(() => {
      getUsers()
    }, [])

    useEffect(() => {
        getUsers()
    }, [trigger])

    async function getUsers(){
      try{
        const response = await apiRequest('users/getUsersFromState', {state: state})
        console.log(response)
        if(response.status == 200){
          setUsers(response.data)
        }
      } catch(e){
        console.log(e)
        alert('Error, please sign in again')
      }
    }


    function getPageTitle(){
      switch(state){
        case -1:
          return 'Denied Users'
        case 0:
          return 'Pending Users'
        case 1:
          return 'Approved Users'
      }
    }

  return (
    <>
      <ReturnButton />
      <div style={{width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginLeft: 'auto', marginRight: 'auto', alignItems: 'center'}}>
        <input placeholder={`Search ${getPageTitle()}`} style={{height: 20, width: '30%'}} onChange={(t) => {setSearch(t.target.value)}}/>
        <h1 style={{width: '100%', textAlign: 'center', textDecoration: 'underline'}}>{getPageTitle()}</h1>
        <input placeholder={`Search ${getPageTitle()}`} style={{visibility: 'hidden'}}/>
      </div>
      <div style={{paddingBottom: 50, overflowY: 'scroll'}}>
        <table className='userTable'>
            <thead className='userTableHeaders'>
                <tr>
                    <th>id</th>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    {/* <th>Status</th> */}
                    {/* <th>Registered Address</th> */}
                    {/* <th>Action</th> */}
                </tr>
            </thead>
            <tbody>
                {users.map(user => {                    
                    if(user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase()) || user.address.toLowerCase().includes(searchTerm.toLowerCase()) || String(user.id).includes(searchTerm)){
                      return <UserEntry userData={user} setTrigger={setTrigger} url={url}/>
                    }
                })}
            </tbody>
    
        </table>
      </div>
      
    </>
        
        
    
  )
}

export default UserList