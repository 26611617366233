import React from 'react'
import '../../css/Documents.css'

function DocumentEntry({linkType, name, pressFunction, contextFunction}) {

    const f = require('../../images/documents/folder.png')

    const image = linkType == "folder" ? require("../../images/documents/folder.png") : linkType == 'file' ? require("../../images/documents/paper.png") : require("../../images/documents/upload_icon.png")

  return (
    <div className='documentEntryContainer' onClick={(e) => {
      pressFunction()
    }} title={name} onContextMenu={(e) => {
      e.preventDefault()
      contextFunction(e)
    }}>
        <img src={image} style={{width: 120, height: '40%'}}/>
        <h2 className='fileText'>{name}</h2>
    </div>
  )
}

export default DocumentEntry