import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'


import '../../css/HeaderBar.css'

function HeaderButton({buttonText, path, setSessionToken, setShowMenu}) {

    const nav = useNavigate()

    const [showDropdown, setShowDropdown] = useState(false)

    function handleClick(e){
      // // setShowDropdown(!showDropdown)
      // console.log(e)
      // try{
      //   setShowMenu(false)
      // } catch (e) {
      //   console.log(e)
      // }
      
      if(e.target.id != 'myAccountButton'){
        setShowDropdown(false)
      }
    }

    useEffect(() => {
      window.addEventListener('click', handleClick);
  
      // cleanup this component
      return () => {
        window.removeEventListener('click', handleClick);
      };
    }, []);

    function goToPage(){
      if(buttonText == 'Signout'){
        
        // nav(path)
      }
      else if(buttonText == 'My Account'){
        setShowDropdown(!showDropdown);
        console.log(showDropdown)
        return;
      }
      else if(buttonText == 'Edit Account Details') {
        nav('/userEdit')
        return;
      }
      else if(buttonText == 'Sign Out'){
        signout()
        return;
      }
      nav(path)
    }

    function accountDropdown(){
      return (
              <div className='accountDropdownContainer' style={showDropdown ? {visibility: 'visible', marginRight: 0} : {visibility: 'hidden'}}>
                  <div>
                    <p onClick={() => {nav('/userEdit')}}>Edit Account</p>
                  </div>
                  {/* <div>
                    <p>Edit Account Details</p>
                  </div> */}
                  <div>
                    <p onClick={signout}>Signout</p>
                  </div>
              </div>
      )
    }

    function signout(){
      sessionStorage.clear()
      setSessionToken(null)
      nav('/')
    }

    function getID(){
      if(buttonText == 'My Account'){
        return 'myAccountButton';
      }
      else{
        return null;
      }
    }

  return (
    <li className='nav-item' style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: 'max-content'}} onClick={goToPage} id={getID()} >
        <a href={'#'} id={getID()} className={'nav-link fw-bold text-white'} style={{fontSize: 18}}>{buttonText}</a>
    </li>
  )
}

export default HeaderButton