import React, { useEffect, useState } from 'react'
import MemberEntry from '../../Components/Members/MemberEntry'
import { apiRequest, decodedToken } from '../../Data/Api/ApiClass'


function MembersPage() {

  const [isMember, setIsMember] = useState(false)

  useEffect(() => {
    const type = decodedToken().userType
    if(type == 'user' || type == 'admin')
      setIsMember(true)
  }, [])


  if(!isMember) return <></>;
  return (
    <>
      <div style={{paddingTop: 50, overflowY: 'auto', height: '90vh'}}> 
        <h1 style={{textAlign: 'center', marginBottom: 100}}>Mountain Lakes TX POA Members</h1>
        <MemberEntry role="President" name="Jeff Blaker" email="jeff.blaker.mlr@gmail.com"/>
        <MemberEntry role="Vice President" name="TBD" email="TBD"/>
        <MemberEntry role="Director of Amenities" name="TBD" email="TBD"/>
        <MemberEntry role="Treasurer" name="Michelle Burdette" email="michelleburdette.mlr@gmail.com"/>
        <MemberEntry role="Secretary" name="Nikki Light" email="nikkilight.mlr@gmail.com"/>
      </div>
    </>
  )
}

export default MembersPage