import React, { useEffect, useState, Image } from 'react'
import HeaderButton from './HeaderButton'
import '../../css/HeaderBar.css'
import { decodedToken } from '../../Data/Api/ApiClass'
import {Navbar, Container, Nav} from 'react-bootstrap'


// const headerColor = "#18509d";

const headerColor = "#0051ff";

// function baseHeaderContainer(content){
//     return (
//         <nav className={'navbar navbar-expand px-5 rounded-bottom-3 shadow py-0 position-sticky gap-5 border-bottom-3 border-black'} style={{backgroundColor: headerColor}}>
//             <img src={require('../../images/mountainlakes.png')} style={{width: 80}} className={'p-1 rounded'}></img>
//             <div className={'container-fluid'}>
//                 <button className={'navbar-toggler'} data-bs-toggle="collapse" data-bs-target={"#nav"}>
//                     <div className={'navbar-toggler-icon'} />
//                 </button>
//                 <div className={"collapse navbar-collapse"} id={"nav"}>
//                     <ul className={'navbar-nav gap-5'}>
//                         {content}
//                     </ul>
//                 </div>
//
//             </div>
//         </nav>
//     )
// }

function baseHeaderContainer(content){
    return (
        <Navbar expand="lg" sticky={'top'} className={'px-0 py-1 shadow rounded-bottom-3 border border-black border-2'} style={{backgroundColor: headerColor}}>
            <Container className={"gap-5"} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100vw', margin: 0}}>
                <img src={require('../../images/mountainlakes.png')} style={{width: 80, backgroundColor: 'white'}} className={'p-1 rounded my-auto border-black border-1 border'}></img>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className={"gap-5"}>
                        {content}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

function Header({sessionToken, setSessionToken}) {
  
  const [showMenu, setShowMenu] = useState(false)
  const [header, setHeader] = useState(guestHeaderBar())



  useEffect(() => {
      const headerColor = "#6cb3e9";
    if(sessionStorage.getItem('token')){
      setHeader(userHeaderBar())
    }
    else{
      setHeader(guestHeaderBar())
    }
  }, [sessionToken])
  

  function userHeaderBar(userType = decodedToken().userType){
      const content = <>
          <HeaderButton buttonText={'Announcements'} path='/announcements' setShowMenu={setShowMenu}/>
          <HeaderButton buttonText={'Members'} path='/members' setShowMenu={setShowMenu}/>
          <HeaderButton buttonText={'Documents'} path='/documents' setShowMenu={setShowMenu}/>
          <HeaderButton buttonText={'Calendar'} path='/calendar' setShowMenu={setShowMenu}/>
          <HeaderButton buttonText={'Directory'} path='/directory' setShowMenu={setShowMenu}/>
          {userType == 'admin' && <HeaderButton buttonText={'Admin'} path='/admin' setShowMenu={setShowMenu}/>}
          <HeaderButton buttonText={'Edit Account Details'} setSessionToken={setSessionToken} />
          <HeaderButton buttonText={'Sign Out'} setSessionToken={setSessionToken} />
      </>

      return baseHeaderContainer(content);

  }

  function guestHeaderBar(){
    const content = <><HeaderButton buttonText={'Sign in'} path='/signin' setShowMenu={setShowMenu}/>
        <HeaderButton buttonText={'Create Account'} path='/createaccount' setShowMenu={setShowMenu}/></>
      return baseHeaderContainer(content);
  }


  return header
}

export default Header