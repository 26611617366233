import jwtDecode from "jwt-decode";

class ApiClass {

    url;
    httpSettings;
    constructor(){
        // this.url = '';
        this.url = 'https://mountainlakestx.com'
        // this.url = 'http://192.168.3.141:3050';
        // this.url = 'http://192.168.1.121:3050';
        this.httpSettings = this.initializeSettings();
    }
    initializeSettings(){
        return {
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json'
            },
            method: 'POST',
        }
    }
    appendTokenToBody(body){
        const token = sessionStorage.getItem('token');
        body.token = token;
        this.httpSettings.body = JSON.stringify(body)
    }
    async apiRequest(routeName, body={}){
        this.appendTokenToBody(body)
        // console.log(this.httpSettings)
        
        
        try{
            const response = await fetch(`${this.url}/${routeName}`, this.httpSettings)
            const data = await response.json()
            
            return {status: response.status, data: data};
        } catch(e) {
            const response = await fetch(`${this.url}/${routeName}`, this.httpSettings)
            const text = await response.blob()

            return {status: response.status, data: text}
        }
    }

    async login(body){
        const settings = this.httpSettings
        settings.body = JSON.stringify(body);

        const response = await fetch(`${this.url}/users/signin`, this.httpSettings)
        const data = await response.json()

        return {status: response.status, data: data};
    }

}
const apiClass = new ApiClass();

export async function apiRequest(routeName, body){
    return await apiClass.apiRequest(routeName, body)
}
export async function apiLogin(body){
    return await apiClass.login(body)
}
export function isAdmin(){
    if(decodedToken().userType == 'admin')
        return true;
    return false;
}
export function decodedToken(){
    const token = sessionStorage.getItem('token');
    return jwtDecode(token)
}
export default apiClass;