import React, { useRef } from 'react'
import '../../css/PostAnnouncement.css'
import ReturnButton from '../../Components/Admin/ReturnButton';
import { apiRequest } from '../../Data/Api/ApiClass';

function PostAnnouncement({url}) {

    const token = sessionStorage.getItem('token')

    const bodyRef = useRef();
    const titleRef = useRef();
    const messageRef = useRef();

    const title = useRef('');
    const body = useRef('')
    
    async function makePost(){
        const response = await apiRequest('announcements/postAnnouncement', {title: `${title.current}`, body: `${body.current}`})
        if(response.status == 200){
            bodyRef.current.value = '';
            titleRef.current.value = '';
            messageRef.current.value = response.data.message
        }
    }

  return (
    <>
        <ReturnButton />
        <div className='formContainer'>
            <h2 className='inputLabel'>Post Announcement</h2>
            <span style={{width: '70%', marginLeft:'auto', marginRight: 'auto'}}>
                {/* <h2 className='inputLabel'>Post Title</h2> */}
                <input ref={titleRef} placeholder='Post title' type='text' className='titleInput' onChange={(t) => {title.current = t.target.value}}/>
            </span>

            <span style={{width: '70%', marginLeft:'auto', marginRight: 'auto'}}>
                {/* <h2 className='inputLabel'>Announcement Body</h2> */}
                <textarea ref={bodyRef} placeholder='Post body' className='inputTextArea' onChange={(t) => {body.current = t.target.value}} />
            </span>
            <button onClick={makePost}>Post Announcement</button>
        </div>
        <h2 ref={messageRef} style={{width: '100%', textAlign: 'center'}}></h2>
    </>
  )
}

export default PostAnnouncement