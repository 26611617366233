import React from 'react'
import '../../css/Admin.css'
import { useNavigate } from 'react-router'

function ReturnButton() {

    const nav = useNavigate();

  return (
    <div>
        <h3 className='returnButton' onClick={() => {nav('/admin')}}> Return to Admin Panel</h3>
    </div>
  )
}

export default ReturnButton