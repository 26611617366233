import React, { useEffect, useRef, useState } from 'react'
import CalendarEntry from './CalendarEntry'
import '../../css/Calendar.css'
import CalendarInfoPanel from './CalendarInfoPanel'
import jwtDecode from 'jwt-decode'
import { apiRequest, decodedToken, isAdmin } from '../../Data/Api/ApiClass'


function Calendar() {

    console.log('hello')
    
    const [monthOffset, setMonthOffset] = useState(0)

    const [calendarHeader, setCalendarHeader] = useState(' ')

    const [days, setDays] = useState([])

    const [events, setEvents] = useState([])
    const [eventIndex, setEventIndex] = useState(0)

    const dayNamesArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']


    useEffect(() => {
        loadCalendar()
    }, [monthOffset])

    async function loadCalendar(){
        console.log('Loading with offset: ' + monthOffset)
        
        const data = await apiRequest(`calendar/build/${monthOffset}`, {})
        const responseDays = data.data;
        const emptyDays = new Array(getStartingDayIndex(monthOffset)).fill(null)


        setCalendarHeader(`${responseDays[0].monthName} ${responseDays[0].year}`)

        const postDays =  (([...emptyDays, ...responseDays]).length + 7 - ([...emptyDays, ...responseDays]).length % 7) - ([...emptyDays, ...responseDays]).length
        const postDaysArray = new Array(postDays).fill(null)

        setDays([...emptyDays, ...responseDays, ...postDaysArray])
        console.log('Days set')
    } 

    async function deleteEvent(id) {
        await apiRequest('calendar/deleteEvent', {eventID: id})
        setEvents([])
        await loadCalendar()
    }


  return (
        <div className={'container my-5 '}>
            <div className={'d-flex flex-column justify-content-center rounded shadow'} style={{backgroundColor: '#18509d'}}>
                <div className='calendarButtonRow py-3'>
                    <button style={{marginLeft: 10}} className='calendarButtons' onClick={() => {setMonthOffset(monthOffset - 1)}}>Prev</button>
                    <h1>{calendarHeader}</h1>
                    <button style={{marginRight: 10}} className='calendarButtons' onClick={() => {setMonthOffset(monthOffset + 1)}}>Next</button>
                </div>        
                <div className='calendarContainer'>
                    {
                        dayNamesArray.map(name => {
                            return <p className='dayHeader'>{name}</p>
                        })
                        
                        
                    }
                    {
                        days.map(d => {
                            return <CalendarEntry day={d?.day} events={d?.events} setEvents={setEvents}/>
                        })
                    }
                </div>
                {
                    events.length > 0 &&  
                        <div className='calendarPopupContainer'>
                            <p className='calendarCloseButton' onClick={() => {
                                setEvents([])
                                setEventIndex(0)
                            }}>X </p>
                            <h3 style={{textDecoration: 'underline', textDecorationColor: 'red', marginBottom: 0}}>{events[eventIndex].type}</h3>
                            <p style={{margin:0, fontStyle: 'italic'}}>{events[eventIndex].month}/{events[eventIndex].day}/{events[eventIndex].year} {events[eventIndex].time}</p>
                            <h2>{events[eventIndex].title}</h2>
                            <p>{events[eventIndex].desc}</p>
                            <p className='sideButton' style={{right: 20, userSelect: 'none'}} onClick={() => {setEventIndex(prev => prev + 1 >= events.length ? prev : prev + 1)}}> {'>'}</p>
                            <p className='sideButton' style={{left: 20, userSelect: 'none'}} onClick={() => {setEventIndex(prev => prev - 1 < 0 ? prev : prev - 1)}}> {'<'} </p>
                            <p className='eventCountLabel'>{eventIndex+1} of {events.length}</p>
                            {isAdmin() && <p className='eventDeleteButton' onClick={() => {deleteEvent(events[eventIndex]?.id)}}>Delete Event #{events[eventIndex]?.id}</p>}
                        </div>
                }
            </div>
        </div>
  )
}

function getStartingDayIndex(monthOffset){
    const date = new Date()
    date.setMonth(date.getMonth() + monthOffset)
    date.setDate(1)

    return date.getDay()
}

export default Calendar