import React from 'react'
import '../../css/Calendar.css'

function CalendarEntry({day, events, setEvents}) {


    // console.log(day)

    // console.log(events?.length == 0)

    function eventClick(){
        console.log(events)
        setEvents(events)
    }

    function drawSquare(){
        if(day){
            if(events != null && events?.length != 0){
                return (<>
                            <div className='calendarSquare eventDay' title="Documents included" onClick={() => {eventClick()}}>
                                <p style={{position: 'absolute', top: 0, left: 0, margin: 0}}>{day}</p>
                                <div className='eventLabelContent'>
                                    <p style={{width: '100%', textAlign: 'center', marginTop: -10, marginBottom: 2, fontSize: 14}}>{events[0].time}</p>
                                    <p style={{width: '100%', textAlign: 'center', marginTop: 2, marginBottom: 2, textDecoration: 'underline', fontSize: 12}}>{events[0].type}</p>
                                    <p style={{width: '100%', textAlign: 'center', marginTop: 2, marginBottom: 2, fontWeight: '600'}}>{events[0].title}</p>
                                    {events.length > 1 && <p style={{width: '100%', textAlign: 'center', marginTop: 2, marginBottom: 2, fontWeight: '500', fontSize: 12, color: 'black', textDecoration: 'underline'}}>{events.length} events scheduled</p>}
                                    <p style={{width: '100%', textAlign: 'center', marginTop: 2, marginBottom: 10, fontSize: 14}}>{events[0].time}</p>
                                </div>
                                <div className='mobileEventColorFill'/>
                            </div>
                    </>
                )
            }
            else{
                return ( <div className='calendarSquare freeCalendarDay'>
                            <p style={{position: 'absolute', top: 0, left: 0, margin: 0}}>{day}</p>
                        </div>
                    )
            }
        }
        else{
            return (<div className='calendarSquare'></div>)
        }

    }

  return (
    // <div className={day != '' ? (event == null ? 'calendarSquare freeCalendarDay' : 'calendarSquare eventDay') : 'calendarSquare'}>{day}</div>
    <>
        {drawSquare()}
    </>
  )
}

export default CalendarEntry