import React, { useEffect } from "react";
import { useState } from "react";

import './App.css'

import Header from "./Components/Header/Header";

import AnnouncementsPage from "./Pages/Announcements/AnnouncementsPage";
import HomePage from "./Pages/HomePage/HomePage";
import AdminPage from "./Pages/AdminPanel/AdminPage";
import MembersPage from "./Pages/Members/MembersPage";
import CalendarPage from "./Pages/Calendar/CalendarPage";
import SigninPage from "./Pages/Signin/SigninPage";

import { Route, Router, Routes } from "react-router";
import UserList from "./Pages/AdminPanel/UserList";
import PostAnnouncement from "./Pages/AdminPanel/PostAnnouncement";
import CreateAccount from "./Pages/CreateAccount/CreateAccount";
import AddCalendar from "./Pages/AdminPanel/AddCalendar";
import AdminUserReports from "./Pages/AdminPanel/AdminUserReports";
import DocumentsPage from "./Pages/Documents/DocumentsPage";

import DirectoryPopup from "./Components/Popup/DirectoryPopup";
import jwtDecode from 'jwt-decode'
import Directory from "./Components/Directory/Directory";
import UserEdit from "./Components/UserEdit/UserEdit";

function App() {

  const [sessionToken, setSessionToken] = useState(sessionStorage.getItem('token'))
  const [popUp, setPopUp] = useState(null)

  useEffect(() => {
    setPopUp(null)
    if(sessionToken){
      if(jwtDecode(sessionToken).visibility == 'null'){
        setTimeout(() => {
          setPopUp(<DirectoryPopup userID={jwtDecode(sessionToken).id} setPopUp={setPopUp}/>)
        }, 1200)
      }
    }
  }, [sessionToken == null])

  return (
      
      <>
        <Header sessionToken={sessionToken} setSessionToken={setSessionToken} />
          {popUp}
          <div className={'container-fluid p-0'}>
            <Routes>
              <Route path='/' element={<HomePage />}/>
              <Route path='/home' element={<HomePage />}/>
              <Route path='/announcements' element={<AnnouncementsPage/>} />
              <Route path='/directory' element={<Directory/>}/>
              <Route path='/members' element={<MembersPage/>} />
              <Route path='/admin' element={<AdminPage />}/>
              <Route path='/calendar' element={<CalendarPage />}/>
              <Route path='/signin' element={<SigninPage setSessionToken={setSessionToken}/>}/>
              <Route path='/userEdit' element={<UserEdit />} />
              <Route path='/createaccount' element={<CreateAccount />} />
              <Route path='/pendingUsers' element={<UserList state={0} />} />
              <Route path='/approvedUsers' element={<UserList state={1} />}/>
              <Route path='/deniedUsers' element={<UserList state={-1} />}/>
              <Route path='/postAnnouncement' element={<PostAnnouncement />}/>
              <Route path='/addCalendar' element={<AddCalendar />} />
              {/* <Route path='/adminUserReports' element={<AdminUserReports url={url}/>} /> */}
              <Route path='/adminUserReports' element={<AdminPage />} />
              <Route path='/documents' element={<DocumentsPage />} />
            </Routes>
          </div>


        </>

  );
}

export default App;
