import React, { useEffect, useState } from 'react'
import DocumentEntry from '../../Components/Documents/DocumentEntry'
import '../../css/Documents.css'
import DocumentUploadForm from '../../Components/Documents/DocumentUploadForm'
import jwtDecode from 'jwt-decode'
import { apiRequest } from '../../Data/Api/ApiClass'

function DocumentsPage() {

    const [currentPath, setCurrentPath] = useState('')

    const [folders, setFolders] = useState([{}])
    const [files, setFiles] = useState([{}])

    const [userType, setUserType] = useState(false)

    const [showUploadForm, setShowUploadForm] = useState("")

    const [contextTarget, setContextTarget] = useState(null)
    const [contextEvent, setContextEvent] = useState(null)


    useEffect(() => { getFileTree() }, [currentPath])

    async function getFileTree(){

        const response = await apiRequest('documents/getFileTree', {path: currentPath})
        const data = response.data;

        setFolders(data.folders)
        setFiles(data.files)
        setUserType(data.userType)
    }

    async function openDocument(fileName){

        const response = await apiRequest('documents/getDocument', {path: `${currentPath}/${fileName}`})
        const data = response.data;

        const blob = new Blob([data], {type: 'application/pdf'})
        const pdfUrl = URL.createObjectURL(blob)
        window.open(pdfUrl, '_blank')
    }
    // async function deleteDocument(){
    //     const path = `${currentPath}/${contextEvent.nativeEvent.target.outerText}`;

    //     const settings = {
    //         headers: new Headers({
    //             'content-type': 'application/json'
    //         }),
    //         method: 'POST',
    //         body: JSON.stringify({
    //             "token": `${token}`,
    //             "path": path
    //         })
    //     }

    //     // console.log(path)

    //     fetch(`${url}/documents/deleteFile`, settings).then(response => response.text()).then(data => {
    //         console.log(data)
    //         getFileTree()
    //     })
    // }

    // function renderContextMenu(){
    //     if(!contextEvent || jwtDecode(token).userType != 'admin'){
    //         console.log(contextEvent)
    //         return null;
    //     }
    //     console.log(contextEvent.nativeEvent.target.outerText)
    //     return  <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 2}} onClick={() => {setContextEvent(null)}} onContextMenu={(e) => {
    //         e.preventDefault()
    //         setContextEvent(null)
    //     }}>
    //                 <div className='contextPanel' style={{top: contextEvent.clientY-60, left: contextEvent.clientX}} onClick={() => {
    //                     setContextEvent(null)
    //                     deleteDocument()
    //                 }}>
    //                     <h1 style={{color: 'red'}}>X</h1>
    //                     <p style={{fontSize: 20, fontWeight: '600'}}>Delete File</p>
    //                 </div>
    //             </div>
    // }

  return (
    <>
        {/* {renderContextMenu()} */}
        <div className='documentHeaderRow'>   
                <p className='documentHeaderButton backButton' onClick={() => {
                        var prevPath = currentPath.split('/').filter(e => e != '')
                        prevPath.pop()
                        setCurrentPath('/' + prevPath.join('/'))
                    }
                }>Back</p>
                <h1 style={{fontSize: 27, width: 'fit-content'}}>{currentPath.split('/').filter(e => e != '').length != 0 ?  currentPath.split('/').filter(e => e != '')[currentPath.split('/').filter(e => e != '').length-1] : 'Documents'}</h1>
                
                {
                    userType == 'admin' &&

                    <div className='adminButtons' style={{flexDirection: 'row', position: 'absolute', right: 100, gap: 50}}>
                        <p className='documentHeaderButton' onClick={() => {setShowUploadForm('Folder')}}>Add Folder</p>
                        <p className='documentHeaderButton' onClick={() => {setShowUploadForm('File')}}>Upload File</p>
                    </div>
                }
                
                
        </div>

        <div className='documentPageContainer'>
            
            {
                folders.map(f => {
                    return <DocumentEntry linkType={"folder"} name={f.name} pressFunction={() => {
                        setCurrentPath(`${currentPath}/${f.name}`);
                    }}/>
                })
            }
            {
                files.map(f => {
                    return <DocumentEntry linkType={"file"} name={f.name} pressFunction={() => { openDocument(f.name) }} contextFunction={(e) => {
                        setContextEvent(e)
                    }}/>
                })
            }
            {showUploadForm && <DocumentUploadForm currentPath={currentPath.split('/').filter(e => e != '').join('/')} setShowUploadForm={setShowUploadForm} showUploadForm={showUploadForm} getFileTree={getFileTree}/>}
        </div>
        </>
  )
}

export default DocumentsPage